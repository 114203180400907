import { getProductPage } from "@10xdev/cms/resolvers/get-product-page";
import { type Locale } from "@10xdev/language-utils";
import PageLayout from "@10xdev/cms/components/PageLayout";
import type { GetStaticPaths, GetStaticProps, NextPage } from "next";

import { getPaths } from "../../../utils/get-paths";

interface Props {
  data: any;
  locale?: Locale;
}

const Product: NextPage<Props> = ({ data, locale }) => (
  <PageLayout data={data} locale={locale} />
);

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  const paths = getPaths("product-pages", locales!);

  return {
    fallback: false,
    paths,
  };
};

export const getStaticProps: GetStaticProps = async ({ locale, params }) => {
  const data = await getProductPage(`${params!.slug}`, locale);

  return {
    props: {
      data: data,
      locale: locale,
    },
  };
};

export default Product;
