import { type Locale } from "@10xdev/language-utils";
import Block from "@10xdev/ui/src/Blocks/Block";
import { GlobalFooter, PlatformFooter } from "@10xdev/ui/src/Footer";
import { Theme } from "@10xdev/ui/src/Footer/types";
import Head from "@10xdev/ui/src/Head";
import ScrollTopButton from "@10xdev/ui/src/ScrollTopButton";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import type { Footer } from "../../definitions/blocks/footer";
import type { ResolvedPage } from "../../resolvers/get-page";

interface Props {
  data: ResolvedPage;
  isPreview?: boolean;
  locale?: Locale;
}

/**
 * A generic layout component for a standard page that
 * consists of sequential blocks.
 */
const PageLayout: FunctionComponent<Props> = ({ data, isPreview, locale }) => {
  const {
    blocks,
    description,
    height = "880px",
    scrollToTop = true,
    socialImage,
    title,
  } = data || {};

  if (!blocks) {
    return null;
  }

  // The headers and footer need to come before and after main
  const header = blocks.find((block) => block.type === "masthead");
  const stickyHeader = blocks.find((block) => block.type === "stickyHeader");
  const mainBlocks = blocks.filter((block) => {
    return (
      block.type !== "footer" &&
      block.type !== "masthead" &&
      block.type !== "stickyHeader"
    );
  });
  const globalFooter = blocks.find(
    (block) => block.type === "footer" && block.variant === "global",
  ) as Footer["footer"] | undefined;

  const platformFooter = blocks.find(
    (block) =>
      block.type === "footer" &&
      (block.variant === "chromium" ||
        block.variant === "visium" ||
        block.variant === "xenium"),
  ) as
    | (Footer["footer"] & {
        variant: "chromium" | "visium" | "xenium";
      })
    | undefined;

  return (
    <>
      {isPreview ? null : (
        <Head description={description} ogImage={socialImage} title={title} />
      )}
      {header ? <Block data={header} isPreview={isPreview} /> : null}
      {stickyHeader ? (
        <Block data={stickyHeader} isPreview={isPreview} />
      ) : null}

      <main
        css={css`
          min-height: ${height};
          overflow-x: hidden;
        `}
      >
        {mainBlocks.map((block, index) => (
          <Block
            data={block}
            isPreview={isPreview}
            key={`${block.type}-block-${index}`}
          />
        ))}
      </main>

      {platformFooter ? (
        <PlatformFooter
          theme={platformFooter.theme as Theme}
          locale={locale}
          platform={platformFooter.variant}
        />
      ) : null}
      {globalFooter ? (
        <GlobalFooter theme={globalFooter.theme as Theme} locale={locale} />
      ) : null}

      {scrollToTop ? <ScrollTopButton top={10} /> : null}
    </>
  );
};

export default PageLayout;
